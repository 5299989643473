/* eslint-env browser */
import React from "react";

function useDisplayedDeviceTypes() {
  return React.useMemo(
    () => ({
      h3r: "Trident Pro Devices",
      hpr: "Trident Nano Devices",
      all: "All Device Types",
    }),
    []
  );
}

export default useDisplayedDeviceTypes;
