/* eslint-env browser */
import React from "react";

import {blue, blueGrey, lightBlue} from "@mui/material/colors";
import darkScrollbar from "@mui/material/darkScrollbar";
import {createTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {useLocalStorage} from "@tzmedical/react-hooks";

import useEnvironmentVariables from "./components/hooks/useEnvironmentVariables.jsx";

const light = "light";
const system = "system";
const dark = "dark";

const SELECTED_THEME_VARIABLE = "br-selected-theme";

const useCustomTheme = () => {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Check the OS settings to see if we need to use Dark Mode
  //---------------------------------------------------------------------------
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  //---------------------------------------------------------------------------
  // Local Storage
  //---------------------------------------------------------------------------

  // Limit the theme to an enum
  const isValidTheme = React.useCallback((theme) => [light, system, dark].includes(theme), []);

  const [storedTheme, setStoredTheme] = useLocalStorage(SELECTED_THEME_VARIABLE, system, isValidTheme);

  const handleSelectTheme = React.useCallback(
    (event, theme) => {
      // if a toggleButton is clicked while selected, this function will be called with null
      if (isValidTheme(theme)) {
        setStoredTheme(theme);
      }
    },
    [isValidTheme, setStoredTheme]
  );

  const isDarkMode = React.useMemo(() => {
    if (!features.supportDarkMode) {
      return false;
    }

    return storedTheme === dark || (storedTheme === system && prefersDarkMode);
  }, [features.supportDarkMode, prefersDarkMode, storedTheme]);

  //---------------------------------------------------------------------------
  // Return a usable theme object each time the storedTheme value changes
  //---------------------------------------------------------------------------
  return React.useMemo(() => {
    return createTheme({
      handleSelectTheme,
      selectedTheme: storedTheme,
      palette: {
        mode: isDarkMode ? dark : light,
        primary: {
          light: "#58a5ef",
          main: lightBlue[800],
          dark: isDarkMode ? "#0070CC" : "#004c8b",
          contrastText: "#ffffff",
        },
        secondary: {
          light: "#80d6ff",
          main: blue[400],
          dark: "#0077c2",
          contrastText: "#ffffff",
        },
        tertiary: {
          light: isDarkMode ? blueGrey[200] : "#62727b",
          main: isDarkMode ? blueGrey[200] : blueGrey[800],
          dark: isDarkMode ? blueGrey[700] : "#102027",
          contrastText: "#ffffff",
        },
        error: {
          main: "#c62828",
        },
        warning: {
          main: "#ffab00",
        },
        info: {
          main: blue[400],
        },
        background: {
          default: isDarkMode ? "#0a0a0a" : "#f5f5f5",
          grey: isDarkMode ? "#272727" : "#e0e0e0",
          chartOnPaper: isDarkMode ? "#0a0a0a" : "#fcfcfc",
        },
        boolean: {
          main: "#42a5f5",
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
      },
      typography: {
        cardRow: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: "0.9375rem",
          lineHeight: "3rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        h5: {
          fontWeight: "500",
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              ...(isDarkMode && darkScrollbar()),
            },
          },
        },
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              cardRow: "div",
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            standardSuccess: {
              backgroundColor: isDarkMode ? "#103510" : "#edf7ed",
            },
            standardError: {
              backgroundColor: isDarkMode ? "#401717" : "#faeded",
            },
            standardWarning: {
              backgroundColor: isDarkMode ? "#644814" : "#fff8ea",
            },
            standardInfo: {
              backgroundColor: isDarkMode ? "#1e3449" : "#eff7fe",
            },
          },
        },
      },
    });
  }, [handleSelectTheme, isDarkMode, storedTheme]);
};

export default useCustomTheme;
