import React from "react";

function useUserReadableRoles() {
  return React.useMemo(
    () => ({
      facilityAdmin: "Facility Admin",
      tzAdmin: "TZ Admin",
      warehouse: "TZ Warehouse",
      tech: "ECG Technician",
      triageTech: "Triage Technician",
      physician: "Physician",
      clinicalStaff: "Clinical Staff",
    }),
    []
  );
}

export default useUserReadableRoles;
