/* eslint-env browser */
import React from "react";
import md5 from "crypto-js/md5";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Avatar from "@mui/material/Avatar";

function GravatarImage({userEmail, userFullName, disabled, sx, useFallbackImage = false}) {
  // Cloud Storage Static Default File
  const defaultImageLink = "https://storage.googleapis.com/bitrhythm-static-files/fallbackUser.png";

  // Fetches Gravatar
  const avatarUrl = React.useMemo(() => {
    const reAlias = /(?<=^[\w-.]+)\+.*?(?=@)/;
    const trimmedEmail = userEmail.trim().toLowerCase().replace(reAlias, "");
    const userHash = md5(trimmedEmail);

    if (useFallbackImage) {
      const fallbackImage = encodeURIComponent(defaultImageLink);
      return `https://www.gravatar.com/avatar/${userHash}?d=${fallbackImage}`;
    }

    return `https://www.gravatar.com/avatar/${userHash}?d=404`;
  }, [userEmail, useFallbackImage]);

  return (
    <Avatar
      alt={userFullName}
      sx={{
        ...sx,

        // Makes the avatar a light grey if the disabled prop passed through is 'true'
        ...(disabled && {filter: "grayscale(100%) opacity(0.5)"}),
      }}
      src={avatarUrl}
    />
  );
}

GravatarImage.propTypes = {
  userEmail: PropTypes.string.isRequired,
  userFullName: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  useFallbackImage: PropTypes.bool,
};

export default GravatarImage;
