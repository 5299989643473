import React from "react";
import PropTypes from "prop-types";

import SvgIcon from "@mui/material/SvgIcon";

// WARNING: if you edit these SVGs in Inkscape, make sure to replace all
// instances of "#000000" with "currentColor" using a text editor afterwards
import {ReactComponent as Trident30Icon} from "./trident-30-icon.svg";
import {ReactComponent as Trident40Icon} from "./trident-40-icon.svg";
import {ReactComponent as TridentComboIcon} from "./trident-combo-icon.svg";

function TridentDeviceIcon({
  // Props
  color,
  fontSize,
  sx,
  firmwareId,
  "data-cy": dataCy,
}) {
  if (firmwareId === "h3r") {
    return <SvgIcon component={Trident40Icon} fontSize={fontSize} color={color} sx={sx} data-cy={dataCy} />;
  }
  if (firmwareId === "hpr") {
    return <SvgIcon component={Trident30Icon} fontSize={fontSize} color={color} sx={sx} data-cy={dataCy} />;
  }
  return <SvgIcon component={TridentComboIcon} fontSize={fontSize} color={color} sx={sx} data-cy={dataCy} />;
}

TridentDeviceIcon.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  sx: PropTypes.object,
  firmwareId: PropTypes.string,
  "data-cy": PropTypes.string,
};
export default React.memo(TridentDeviceIcon);
