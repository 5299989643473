/* eslint-env browser */
import React from "react";

function useDisplayedReportStates() {
  return React.useMemo(
    () => ({
      generated: "Pending QC",
      published: "Published",
      submitted: "Pending Signature",
      signed: "Signed",
      printed: "Printed",
      rejectedByTech: "Rejected by Tech",
      rejectedByPhysician: "Rejected by Clinic",
    }),
    []
  );
}

export default useDisplayedReportStates;
