import React from "react";
import {ConfirmProvider} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useCustomTheme from "../../customizeReactTheme.jsx";
import {JwtProvider} from "./useJwt.jsx";

//---------------------------------------------------------------------------
// Fonts
//---------------------------------------------------------------------------
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Define options outside component to avoid extra re-renders
const defaultOptions = {
  dialogProps: {"data-cy": "confirmation-dialog"},
  cancellationButtonProps: {color: "secondary", "data-cy": "cancel-confirmation-button"},
  confirmationButtonProps: {
    color: "secondary",
    variant: "contained",
    "data-cy": "confirm-button",
  },
  allowClose: false,
};

function AddProviders({children}) {
  //---------------------------------------------------------------------------
  // Customize the theme - this will move to app.js eventually
  //---------------------------------------------------------------------------
  const theme = useCustomTheme();

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <JwtProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <ConfirmProvider defaultOptions={defaultOptions}>{children}</ConfirmProvider>
          </LocalizationProvider>
        </JwtProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

AddProviders.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AddProviders;
