/* eslint-env browser */
import React from "react";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "./useEnvironmentVariables.jsx";

function useStudyTypeNames(variant = "normal") {
  const {features} = useEnvironmentVariables();

  return React.useMemo(() => {
    const displayableStudyTypes = {
      holter: "Holter",
      extendedHolter: "Extended Holter",
      wirelessHolter: "Wireless Holter",
      wirelessExtendedHolter: "Wireless Extended Holter",
      mct: "Mobile Cardiac Telemetry (MCT)",
      cem: "Cardiac Event Monitoring (CEM)",
      mctWithFullDisclosure: "MCT with Full Disclosure",
      cardiacRehab: "Cardiac Rehab",
    };

    if (!features.extendedHolterStudyType) {
      delete displayableStudyTypes.extendedHolter;
      delete displayableStudyTypes.wirelessExtendedHolter;
    }

    if (variant === "complex") {
      return {
        ...displayableStudyTypes,
        mct: {
          short: "MCT",
          long: "Mobile Cardiac Telemetry (MCT)",
        },
        cem: {
          short: "CEM",
          long: "Cardiac Event Monitoring (CEM)",
        },
        mctWithFullDisclosure: {
          short: "MCT (Full Disclosure)",
          long: "MCT with Full Disclosure",
        },
      };
    }

    return displayableStudyTypes;
  }, [variant, features.extendedHolterStudyType]);
}

export default useStudyTypeNames;
