import React from "react";
import PropTypes from "prop-types";

const PageContext = React.createContext();

export function PageProvider({children}) {
  const [TopBarOverlay, setTopBarOverlay] = React.useState(null);

  // Memoize the value to reduce rerenders
  const value = React.useMemo(
    () => ({
      TopBarOverlay,
      setTopBarOverlay,
    }),
    [TopBarOverlay]
  );

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

PageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContext;
