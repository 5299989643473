/* eslint-env browser */
import React from "react";

function useDisplayedStudyStatuses() {
  return React.useMemo(
    () => ({
      active: "Active",
      finalized: "Finalized",
      archived: "Archived",
      failed: "Failed",
    }),
    []
  );
}

export default useDisplayedStudyStatuses;
