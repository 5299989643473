/* eslint-env browser */

export const facilityForwardingModes = {
  inboxForwarding: {
    id: "inboxForwarding",
    name: "BitRhythm Inbox",
    extendedName: "Send ECG data to BitRhythm Inbox",
    description: "The device will forward all traffic to BitRhythm Inbox.",
  },
  nativeForwarding: {
    id: "nativeForwarding",
    name: "Native Forwarding",
    extendedName: "Forward ECG data to a Trident compatible server",
    description: "All traffic will be forwarded to the specified URL endpoint.",
  },
  compatForwarding: {
    id: "compatForwarding",
    name: "Compatibility Forwarding",
    extendedName: "Forward ECG data to an Aera CT compatible server",
    description: `The device will be reconfigured using the facility's current compatibility
      file and forward all traffic to the specified URL endpoint using this mode. Use this
      when your software only supports the Aera CT communication protocol.`,
  },
};

export const deviceForwardingModes = {
  ...facilityForwardingModes,
  useDefaultModeAndAddress: {
    id: "useDefaultModeAndAddress",
    name: "Use Facility Default",
    description:
      "The device will follow the facility default forwarding mode. The current facility default is",
  },
  useDefaultModeOnly: {
    id: "useDefaultModeOnly",
    name: "Use Facility Default Mode with Device URL",
    description: "The current facility default forwarding mode is",
  },
};

//---------------------------------------------------------------------------
// Returns a description of a given device's forwarding mode
//
// E.g. "The current facility default forwarding mode is Native Forwarding.
//       The device will send data to the specified URL."
//---------------------------------------------------------------------------
export function getDeviceForwardingDescription(
  deviceForwardingMode,
  facilityForwardingMode,
  facilityForwardingUrl
) {
  let {description} = deviceForwardingModes[deviceForwardingMode];

  if (deviceForwardingMode.startsWith("useDefaultMode")) {
    description += ` ${deviceForwardingModes[facilityForwardingMode].name}.`;

    if (deviceForwardingMode === "useDefaultModeOnly") {
      description += " The device will send data to the specified URL.";
    } else if (
      deviceForwardingMode === "useDefaultModeAndAddress" &&
      facilityForwardingMode !== "inboxForwarding"
    ) {
      description += ` The current facility URL endpoint is ${facilityForwardingUrl}.`;
    }
  }

  return description;
}

//---------------------------------------------------------------------------
// Returns a given device's derivative forwarding mode, including the URL
//
// E.g. If the device's forwarding mode is 'useDefaultModeAndAddress',
//      returns "Native Forwarding (test.com)"
//---------------------------------------------------------------------------
export function getFullDeviceForwardingMode(
  deviceForwardingMode,
  deviceForwardingUrl,
  facilityForwardingMode,
  facilityForwardingUrl
) {
  const forwardingMode =
    deviceForwardingMode && deviceForwardingMode.startsWith("useDefaultMode")
      ? facilityForwardingMode
      : deviceForwardingMode;
  const forwardingUrl =
    deviceForwardingMode === "useDefaultModeAndAddress" ? facilityForwardingUrl : deviceForwardingUrl;

  return `${facilityForwardingModes[forwardingMode].name}${
    forwardingUrl && forwardingMode !== "inboxForwarding" ? ` (${forwardingUrl})` : ""
  }`;
}
