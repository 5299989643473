import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Menu from "@mui/icons-material/Menu";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import logo from "../assets/br-logo-admin-inverted.svg";
import PageContext from "./contexts/PageContext.jsx";
import AvatarMenu from "./AvatarMenu.jsx";
import SearchBar from "./SearchBar.jsx";

function TopBar({
  // Props
  onClick,
  onClose,
}) {
  const ultraNarrow = useMediaQuery("(max-width:399px)");

  //---------------------------------------------------------------------------
  // State management for Top Bar Overlay
  //---------------------------------------------------------------------------
  const {TopBarOverlay} = React.useContext(PageContext);

  return (
    <AppBar position="fixed" sx={{zIndex: (prev) => prev.zIndex.drawer + 1}} data-cy="top-app-bar">
      {TopBarOverlay && (
        <Toolbar
          sx={{
            backgroundColor: ({palette}) =>
              palette.mode === "dark" ? palette.grey[700] : palette.primary.dark,
            width: "100%",
            position: "fixed",
            zIndex: 1,
          }}
        >
          {TopBarOverlay}
        </Toolbar>
      )}
      <Toolbar>
        <Grid container spacing={1} sx={{display: "flex", alignItems: "center", width: "100%"}}>
          <Grid size="auto" align="left">
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={onClick}>
              <Menu />
            </IconButton>
          </Grid>
          <Grid size="auto" align="left">
            <Box sx={{mt: "7px", pl: 1, display: {xs: "none", sm: "block"}}}>
              <img src={logo} alt="BitRhythm Admin" height="50" />
            </Box>
          </Grid>
          <Grid size="grow" align="center" onClick={onClose}>
            <SearchBar />
          </Grid>
          {!ultraNarrow && (
            <Grid size="auto">
              <AvatarMenu />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;

TopBar.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};
