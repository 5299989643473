import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

function DialogTitleBar({
  // Props
  title,
  icon,
  children,
}) {
  return (
    <>
      <DialogTitle>
        <Stack direction="row" sx={{justifyContent: "space-between"}}>
          <Stack direction="row" spacing={1} sx={{alignItems: "center", flexWrap: "wrap"}}>
            {icon}
            <Box>{title}</Box>
          </Stack>
          {children}
        </Stack>
      </DialogTitle>
      <Divider sx={{bgcolor: "secondary.main"}} />
    </>
  );
}

DialogTitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default React.memo(DialogTitleBar);
