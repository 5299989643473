import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from "@mui/material/Typography";

function NoResults() {
  return (
    <Typography variant="h5" data-cy="no-results-message" sx={{textAlign: "center", paddingTop: "100px"}}>
      No Results
    </Typography>
  );
}

export default NoResults;
