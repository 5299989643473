import React from "react";
import {Link, useLocation} from "react-router-dom";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const sxSelected = {
  "&.Mui-selected": {
    backgroundColor: "divider",
    "&:hover": {backgroundColor: "divider"},
  },
};
const sxIcon = {color: "tertiary.main", minWidth: "35px"};
const sxText = {variant: "body2", color: "tertiary.main"};

function NavButton({
  // Props
  Icon,
  text,
  to,
  onClick,
  "data-cy": dataCy,
}) {
  // Use location to highlight when our link matches the current page
  const location = useLocation();

  if (to) {
    return (
      <ListItemButton
        component={Link}
        to={to}
        onClick={onClick}
        selected={location.pathname === to}
        data-cy={dataCy}
        dense
        sx={sxSelected}
      >
        <ListItemIcon sx={sxIcon}>{Icon}</ListItemIcon>
        <ListItemText primaryTypographyProps={sxText} primary={text} />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton component={Link} onClick={onClick} data-cy={dataCy}>
      <ListItemIcon sx={sxIcon}>{Icon}</ListItemIcon>
      <ListItemText primaryTypographyProps={sxText} primary={text} />
    </ListItemButton>
  );
}

NavButton.propTypes = {
  Icon: PropTypes.node,
  text: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  "data-cy": PropTypes.string,
};

export default React.memo(NavButton);
