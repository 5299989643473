/* eslint-env browser */
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";

import useJwt from "../contexts/useJwt.jsx";

//---------------------------------------------------------------------------
const useLogout = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const {setJwt} = useJwt();

  return React.useCallback(
    (message = "You have been logged out.") => {
      setJwt(null);
      navigate("/login", {state: {from: pathname, message}, replace: true});
    },
    [navigate, pathname, setJwt]
  );
};

export default useLogout;
