import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Close from "@mui/icons-material/Close";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Info from "@mui/icons-material/Info";
import WarningAmber from "@mui/icons-material/WarningAmber";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ReactAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import {useTheme} from "@mui/material/styles";

import DialogTitleBar from "./DialogTitleBar.jsx";

function Alert({
  // Props
  title,
  message,
  setMessage,
  level,
  variant,
  otherProps,
}) {
  const dialogIcon = React.useMemo(() => {
    if (level === "error") {
      return <ErrorOutline color="error" />;
    }
    if (level === "warning") {
      return <WarningAmber color="warning" />;
    }
    if (level === "info") {
      return <Info color="info" />;
    }
    if (level === "success") {
      return <CheckCircleOutline color="success" />;
    }
    return null;
  }, [level]);
  const dialogTitle = React.useMemo(() => {
    if (title) {
      return title;
    }
    if (level === "error") {
      return "Error";
    }
    if (level === "warning") {
      return "Warning";
    }
    if (level === "info") {
      return "Info";
    }
    if (level === "success") {
      return "Success";
    }
    return "Alert";
  }, [title, level]);

  const handleClose = React.useCallback(() => setMessage(null), [setMessage]);
  const theme = useTheme();

  if (variant === "dialog") {
    return (
      <Dialog open={!!message} maxWidth="sm" fullWidth {...otherProps}>
        <DialogTitleBar title={dialogTitle} icon={dialogIcon} />
        <DialogContent sx={{overflowWrap: "anywhere"}}>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        {setMessage && (
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              autoFocus
              data-cy="close-alert"
              sx={{m: 1}}
            >
              Dismiss
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
  if (variant === "snackbar") {
    return (
      <Snackbar
        open={!!message}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        sx={{bottom: {xs: 90, sm: 0}, maxWidth: "lg"}}
        {...otherProps}
      >
        <ReactAlert
          severity={level}
          variant="outlined"
          action={
            setMessage && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
                data-cy="close-alert"
              >
                <Close fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{mb: 2, backgroundColor: theme.palette.background.paper}}
        >
          {message}
        </ReactAlert>
      </Snackbar>
    );
  }
  return (
    <Box sx={{width: "100%"}} {...otherProps}>
      <Collapse in={!!message}>
        <ReactAlert
          severity={level}
          action={
            setMessage && (
              <IconButton
                aria-label="close"
                color={level === "info" ? "secondary" : "inherit"}
                size="small"
                onClick={handleClose}
                data-cy="close-alert"
              >
                <Close fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{...(level === "info" && setMessage && {backgroundColor: "white"})}}
        >
          {message}
        </ReactAlert>
      </Collapse>
    </Box>
  );
}

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  setMessage: PropTypes.func,
  level: PropTypes.string.isRequired,
  variant: PropTypes.string,
  otherProps: PropTypes.object,
};

export default Alert;
